<template>
	<div class="field">
		<div class="title">{{title}}</div>
		<div class="time">报名日期 {{time}}</div>
		<div class="status" :style="'color:'+colors[index]">{{name}}</div>
	</div>
</template>

<script>
	export default{
		props:{
			//标题
			title: {
				type: String,
				default: ''
			},
			//标题
			time: {
				type: String,
				default: ''
			},
			index:{
				type:Number,
				default:0,
			},
			name: {
				type: String,
				default: ''
			},
		},
		data(){
			return {
				colors:['#2CA200','#0050A2','#D0021B','#828284'],
				status:['已提交，待审核','审核中，待反馈','需补充信息，等待客服联系','审核完成'],
			}
		}
	}
</script>

<style lang="less" scoped="scoped">
	.field{
		width: 92%;
		height: auto;
		background:rgba(255,255,255,1);
		box-shadow:0px -2px 5px 2px rgba(162,162,162,0.5);
		border-radius:5px;
		margin: 15px 15px 15px;
		padding: 15px;
		box-sizing: border-box;
		.title{
			width: 100%;
			height: auto;
			font-size:16px;
			font-weight:600;
			color:rgba(49,49,52,1);
			box-sizing: border-box;
		}
		.time{
			width: 100%;
			height: auto;
			font-size:14px;
			font-weight:400;
			color:rgba(130,130,132,1);
			line-height:14px;
			margin-top: 15px;
			padding-bottom: 15px;
			border-bottom: 1px solid #D5D5D5;
		}
		
		.status{
			width: 100%;
			height: auto;
			font-size:15px;
			font-weight:400;
			line-height:15px;
			margin-top: 15px;
		}
		
	}
	
</style>
